<template>
<div class='auth-setting'>
  <layout-header />
  <main class='auth-setting__main' v-if="param.mode == 'resetPassword'">
    <section class='auth-setting__main__modal'>
      <div class='auth-setting__main__modal__title'>
        <h3>パスワードの再設定</h3>
        <p>新たに設定するパスワードを入力して下さい。</p>
      </div>
      <div class='auth-setting__main__modal__form'>
        <input
          :type='inputTypePassword ? "password" : "text"'
          :placeholder='password.field'
          v-model="password.value"
          @keyup="validPassword"
        />
        <p v-if="password.valid.message.length !== 0">
          {{ password.valid.message[0] }}
        </p>
      </div>
      <div class='auth-setting__main__modal__form'>
        <input
          :placeholder='confirmPassword.field'
          :type='inputTypePassword ? "password" : "text"'
          v-model="confirmPassword.value"
          @keyup="validConfirmPassword"
        />
        <p v-if="confirmPassword.valid.message.length !== 0">
          {{ confirmPassword.valid.message[0] }}
        </p>
      </div>
      <!-- <label class='section-auth-signup__modal__mail__type'>
        <p>パスワードを表示する</p>
        <input
          type="checkbox"
          :checked="!inputTypePassword"
          @click='changeInputTypePassword()'
        />
      </label> -->
      <button-normal
        class='auth-setting__main__modal__button'
        @emitClick='changePassword'
        :loading='isLoading.changePassword'
        :disabled='judgeDisabledChangePassword'
      >
        送信
      </button-normal>
    </section>
  </main>
  <main
    class='auth-setting__main'
    v-if="param.mode === 'verifyEmail' && param.changeEmailToken === null"
  >
    <section class='auth-setting__main__modal'>
      <div class='auth-setting__main__modal__title'>
        <h3>認証が完了しました</h3>
        <p>メールアドレスの認証を完了しました。<br>下のボタンをクリックし早速Donblerで学習を開始しましょう！</p>
      </div>
      <button-normal
        @emitClick='checkEmail'
        :loading='isLoading.verifyEmail'
      >
        学習を開始する
      </button-normal>
    </section>
  </main>
  <main
    class='auth-setting__main'
    v-if="param.mode === 'verifyEmail' && param.changeEmailToken !== null"
  >
    <section class='auth-setting__main__modal'>
      <div class='auth-setting__main__modal__title'>
        <h3>メールアドレス変更完了</h3>
        <p>メールアドレスの変更を完了しました。<br>トップページから再度ログインをお願いします。</p>
      </div>
      <button-normal
        @emitClick='checkChangeEmail'
        :loading='isLoading.verifyEmail'
      >
      トップページに戻る
      </button-normal>
    </section>
  </main>
  <notifications group="top-right"/>
  <layout-footer />
</div>
</template>
<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import axios from 'axios';
import * as firebase from 'firebase/auth';
import '../firebaseConfig';

const auth = firebase.getAuth();

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
  },
  data() {
    return {
      isLoading: {
        changePassword: false,
        verifyEmail: false,
      },
      param: {
        apiKey: null,
        mode: null,
        oobCode: null,
        continueUrl: null,
        changeEmailToken: null,
      },
      inputTypePassword: true,
      password: {
        value: '',
        field: 'パスワード',
        valid: {
          message: [],
          rule: {
            maxLength: 20,
            minLength: 8,
          },
        },
      },
      confirmPassword: {
        value: '',
        field: 'パスワード(確認)',
        valid: {
          message: [],
          rule: {
          },
        },
        edited: false,
      },
    };
  },
  created() {
    this.setUrlQueryToParam();
    this.redirectTopMissingParam();
    this.$store.dispatch('execLogout');
  },
  computed: {
    judgeDisabledChangePassword() {
      return this.password.valid.message.length !== 0
       || this.confirmPassword.valid.message.length !== 0
       || this.password.value === ''
       || this.confirmPassword.value === '';
    },
  },
  methods: {
    async checkChangeEmail() {
      this.isLoading.verifyEmail = true;
      const params = {
        changeEmailToken: this.param.changeEmailToken,
        oobCode: this.param.oobCode,
      };
      firebase.applyActionCode(auth, this.param.oobCode)
        .then(async () => {
          await axios.post(
            `${process.env.VUE_APP_HOST}/api/login/emailCertification`,
            params,
          )
            .then(() => {
              this.$router.push({ name: 'Top' });
            })
            .catch((e) => {
              if (e.message.match('invalid-action-code')) {
                this.errorFunc(
                  'メール認証に失敗しました',
                  'このリンクは使用済みです',
                );
              } else {
                this.errorFunc(
                  'メール認証に失敗しました',
                  'しばらく時間を空けてから再度お試しください',
                );
              }
              this.isLoading.verifyEmail = false;
            });
        })
        .catch((e) => {
          if (e.message.match('invalid-action-code')) {
            this.errorFunc(
              'メール認証に失敗しました',
              'このリンクは使用済みです',
            );
          } else {
            this.errorFunc(
              'メール認証に失敗しました',
              'しばらく時間を空けてから再度お試しください',
            );
          }
          this.isLoading.verifyEmail = false;
        });
    },
    checkEmail() {
      this.isLoading.verifyEmail = true;
      firebase.applyActionCode(auth, this.param.oobCode)
        .then(() => {
          window.location.href = this.param.continueUrl;
        })
        .catch((e) => {
          if (e.message.match('invalid-action-code')) {
            this.errorFunc(
              'メール認証に失敗しました',
              'このリンクは使用済みです',
            );
          } else {
            this.errorFunc(
              'メール認証に失敗しました',
              'しばらく時間を空けてから再度お試しください',
            );
          }
          this.isLoading.verifyEmail = false;
        });
    },
    changePassword() {
      this.checkAllValid();
      if (this.password.valid.message.length === 0
        && this.confirmPassword.valid.message.length === 0) {
        this.isLoading.changePassword = true;
        firebase.verifyPasswordResetCode(auth, this.param.oobCode)
          .then(() => {
            firebase.confirmPasswordReset(auth, this.param.oobCode, this.password.value)
              .then(async () => {
                this.$router.push({
                  name: 'Top',
                  query: { passwordChange: 'success' },
                });
              })
              .catch(() => {
                this.errorFunc('メール認証に失敗しました');
                this.isLoading.changePassword = false;
              });
          })
          .catch((e) => {
            if (e.message.match('invalid-action-code')) {
              this.errorFunc(
                'メール認証に失敗しました',
                'このリンクは使用済みです',
              );
            } else {
              this.errorFunc('メール認証に失敗しました');
            }
            this.isLoading.changePassword = false;
          });
      }
    },
    errorFunc(title, text = 'しばらく時間を空けてから再度お試しください') {
      this.$notify({
        title,
        text,
        speed: 400,
        type: 'error',
        group: 'top-right',
      });
    },
    setUrlQueryToParam() {
      this.param.apiKey = this.$route.query.apiKey;
      this.param.mode = this.$route.query.mode;
      this.param.oobCode = this.$route.query.oobCode;
      this.param.continueUrl = this.$route.query.continueUrl;

      if (this.$route.query.continueUrl) {
        const decodeContinueUrl = decodeURI(this.$route.query.continueUrl).replace('changeEmailToken%3D', 'changeEmailToken=');
        const params = new URL(decodeContinueUrl).searchParams;
        this.param.changeEmailToken = params.get('changeEmailToken');
      }
    },
    redirectTopMissingParam() {
      if (
        this.param.apiKey === null
        || this.param.mode === null
        || this.param.oobCode === null
      ) {
        this.$router.push({ name: 'Top' });
      }
    },
    changeInputTypePassword() {
      this.inputTypePassword = !this.inputTypePassword;
    },
    checkAllValid() {
      this.validPassword();
      this.validConfirmPassword();
    },
    validPassword() {
      const { rule } = this.password.valid;
      const target = this.password.value;
      const valid = [];

      valid.push(this.checkMaxLength(this.password.field, rule.maxLength, target.length));
      valid.push(this.checkMinLength(this.password.field, rule.minLength, target.length));
      valid.push(this.hasLargeChar(this.password.field, target));
      valid.push(this.hasSmallChar(this.password.field, target));
      valid.push(this.hasNum(this.password.field, target));

      this.password.valid.message = valid.filter((v) => v !== '');
      if (this.confirmPassword.edited) {
        this.validConfirmPassword();
      }
    },
    validConfirmPassword() {
      const target = this.confirmPassword.value;
      const valid = [];
      this.confirmPassword.edited = true;

      this.checkStrMatchResult = this.checkStrMatch(
        this.confirmPassword.field,
        this.password.field,
        target,
        this.password.value,
      );

      valid.push(this.checkStrMatchResult);
      this.confirmPassword.valid.message = valid.filter((v) => v !== '');
    },
    checkMaxLength(field, maxLen, targetLen) {
      if (maxLen < targetLen) {
        return `${field}は${maxLen}文字以内で入力してください`;
      }
      return '';
    },
    checkMinLength(field, minLen, targetLen) {
      if (minLen > targetLen) {
        return `${field}は${minLen}文字以上で入力してください`;
      }
      return '';
    },
    checkStrMatch(field1, field2, target1, target2) {
      const reg = new RegExp(`^${target2}$`);
      if (!target1.match(reg)) {
        return `${field1}と${field2}は一致している必要があります`;
      }
      return '';
    },
    hasLargeChar(field, target) {
      if (!target.match(/[A-Z]/)) {
        return `${field}に大文字は必須です`;
      }
      return '';
    },
    hasSmallChar(field, target) {
      if (!target.match(/[a-z]/)) {
        return `${field}に小文字は必須です`;
      }
      return '';
    },
    hasNum(field, target) {
      if (!target.match(/[0-9]/)) {
        return `${field}に数字は必須です`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scpoed>
.auth-setting {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    a {
      text-decoration: none;
      color: $color-black;
    }
    a:visited {
      color: $color-black;
    }
    &__modal {
      background-color: $color-white;
      width: 500px;
      text-align: center;
      padding: 30px 30px 35px;
      &__title {
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 14px;
          margin: 10px 0 20px;
        }
      }
      &__form {
        margin-bottom: 10px;
        input {
          font-size: 12px;
          padding: 10px;
          width: 300px;
          border: none;
          outline: none;
          background-color: $color-gray;
          border-radius: 8px;
          margin-bottom: 5px;
        }
        p {
          color: $color-red;
          font-size: 12px;
          margin: 0;
        }
      }
      &__button {
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 650px) {
.auth-setting__main__modal__form input {
  font-size: 16px;
}
}
</style>
